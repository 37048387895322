export default class Utils {

    static pad0ToSingleDigits = (number: number): string => {
        if(number >= 0 && number <= 9) return `0${number}`;
        return `${number}`;
    }

    static pad00ToSingleDigits = (number: number): string => {
        if(number >= 0 && number <=9) return `0:0${number}`;
        return `0:${number}`;
    }

    static pad00ToSingleNegativeDigits = (number: number): string => {
        if(number > -10) return `-0:0${Math.abs(number)}`;
        return `-0:${Math.abs(number)}`;
    }
}