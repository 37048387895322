import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import LoadingPanel from "../../components/LoadingPanel/LoadingPanel";
import { BackendData, Departure, Shift, View} from "../../types/entities.d";
import { VIEW_INTERVAL_TIME } from "../../utils/constants";
import ChartTable from "../ChartTable/ChartTable";
import DepartmentsTable from "../DeparturesTable/DeparturesTable";


const Home = () => {

    const [backendData, setBackendData] = useState<BackendData>();
    const [type, setType] = useState<View>(View.departures);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [shift, setShift] = useState<Shift>(Shift.afternoon);
    const [department, setDepartment] = useState<string>('');
    const [pwoDone, setPwoDone] = useState<string>('');
    const [forecast, setForecast] = useState<string>('');
    const [time, setTime] = useState<string>('');
    const [perTimeUnit, setPerTimeUnit] = useState<string>('');
    const [workers, setWorkers] = useState<string>('');
    const [chartValues, setChartValues] = useState<number[]>([]);
    const [chartHours, setChartHours] = useState<string[]>([]);
    const [departures, setDepartures] = useState<Departure[]>([]);

    const fetchData = () => {
        return fetch("/api.json").then(response => response.json()).then(data => setBackendData(data));
    }

    useEffect(() => {
        const apiInterval = setInterval(() => {
            fetchData();
        }, 5000);
        if(backendData) {
            setShift(backendData.shift);
            setDepartment(backendData.department);
            setPwoDone(backendData.chartsView.pwoDone);
            setForecast(backendData.chartsView.forecast);
            setTime(backendData.chartsView.time);
            setPerTimeUnit(backendData.chartsView.perTimeUnit);
            setWorkers(backendData.chartsView.workers);
            setChartValues(backendData.chartsView.chartValues);
            setChartHours(backendData.chartsView.chartHours);
            setDepartures(backendData.departures);
            setIsLoading(false);
        }
        return () => {
            clearInterval(apiInterval);
        }
    }, [backendData]);

    useEffect(() => {
        const interval = setInterval(() => {
            type === View.charts ? setType(View.departures) : setType(View.charts);
        }, VIEW_INTERVAL_TIME);
        return () => {
            clearInterval(interval);
        };
    }, [type]);

    return (
        !isLoading ?
            (
                <div className="main">
                    <Header type={type} shift={shift} department={department} />
                    {type === View.charts ?
                        <ChartTable pwoDone = {pwoDone} forecast={forecast} time={time} perTimeUnit={perTimeUnit} workers={workers} chartHours={chartHours} chartValues={chartValues}/>
                        :
                        <DepartmentsTable shift={shift} departures={departures} />
                    }
                </div>
            ) :
            (
                <LoadingPanel />
            )
    );
}

export default Home;
