export type BackendData = {
//    type: number,
   shift: number,
   department: string,
   chartsView: ChartsView,
   departures: Departure[],
}; 

export type Departure = {
    plannedTime: string,
    route: string,
    dockNumber?: number,
    realTime?: string,
};

export enum Shift {
    afternoon = 1,
    night = 2,
}

export enum View {
    charts = 1,
    departures = 2,
}

export enum ChartColorway {
    primary = 1,
    secondary = 2,
}

type ChartsView = {
    pwoDone: string,
    forecast: string,
    time: string,
    perTimeUnit: string,
    workers: string,
    chartValues: number[],
    chartHours: string[],
}