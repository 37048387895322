import { useEffect, useState } from 'react';
import mainLogo from '../../assets/main-logo.svg';
import { Shift, View} from '../../types/entities.d';
import Utils from '../../utils/Utils';
import { TIMER_INTERVAL_TIME } from '../../utils/constants';

//style
import './Header.scss';

type Props = {
    type: View,
    shift: Shift,
    department: string,
}

const Header = (props: Props) => {

    const { type, shift, department } = props;
    const [date, setDate] = useState<Date>(new Date());

    useEffect(() => {
        const dateInterval = setInterval(() => {
            setDate(new Date());
        }, TIMER_INTERVAL_TIME);
        return () => {
            clearInterval(dateInterval);
        };
    }, []);

    return (
        <header className='header--component'>
            <span className='time'>
                {`${Utils.pad0ToSingleDigits(date.getHours())}:${Utils.pad0ToSingleDigits(date.getMinutes())}`}
            </span>
            <span className='date'>
                {`${Utils.pad0ToSingleDigits(date.getDate())}.${Utils.pad0ToSingleDigits((date.getMonth() + 1))}.${Utils.pad0ToSingleDigits(date.getFullYear())}`}
            </span>
            <p className='type'>{ type === View.departures ? 'Tablica odjazdów' : 'Tablica wykresów'}</p>
            <p className='shift'>Zmiana { shift === Shift.afternoon ? 'popołudniowa' : 'nocna'} | {department}</p>
            <img className='w-[20rem]' src={mainLogo} alt='Inpost.pl' />
            <span className='counter'><span>{ shift === Shift.afternoon ? '1' : '2'}</span>/2</span>
        </header>
    );
}

export default Header;