import { Shift } from "../../../types/entities.d";
import Utils from "../../../utils/Utils";
import { parseISO } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';
import { useEffect, useState } from "react";
import { TIMER_INTERVAL_TIME } from "../../../utils/constants";

type Props = {
    shift: Shift,
    plannedTime: string,
    route: string,
    dockNumber?: number,
    realTime?: string,
}

const Row = (props: Props) => {

    const { shift, plannedTime, route, dockNumber, realTime } = props;
    const [minutesLeft, setMinutesLeft] = useState<number>(0);
    const date = parseISO(plannedTime);

    useEffect(() => {
        const date = parseISO(plannedTime);
        const now = new Date();
        setMinutesLeft(differenceInMinutes(date, now));
    }, [plannedTime]);

    useEffect(() => {
        const interval = setInterval(() => {
            const date = parseISO(plannedTime);
            const now = new Date();
            setMinutesLeft(differenceInMinutes(date, now));
        }, TIMER_INTERVAL_TIME);
        return () => {
            clearInterval(interval);
        };
    }, [minutesLeft, plannedTime]);

 return (
    <div className={`row ${minutesLeft && minutesLeft < 31 ? '--active' : ''}`}>
        <span className="planned-time">{getHours(date)}:{Utils.pad0ToSingleDigits(getMinutes(date))}</span>
        {shift === Shift.afternoon ? 
        (
            <span className={`minutes 
                ${minutesLeft < 6 ? '--red' 
                : (minutesLeft < 16 ? '--yellow' 
                : (minutesLeft < 31 ? '--green' 
                : ''))}
            `}>
                {
                    minutesLeft < 31 ?
                        minutesLeft >= 0 ? Utils.pad00ToSingleDigits(minutesLeft) : Utils.pad00ToSingleNegativeDigits(minutesLeft) : ''
                }
            </span>
        ):
        (
            <span className="real-time">{realTime}</span>
        )
        }
        <span className="route">{route}</span>
        <span className="number">{minutesLeft < 31 ? dockNumber : ''}</span>
    </div>
 );
}

export default Row;