import './ChartTable.scss';
import { Chart as ChartJS, registerables } from 'chart.js';
import BarChart from './subcomponents/BarChart';
import { ChartColorway } from '../../types/entities.d';
ChartJS.register(...registerables);

type Props = {
    pwoDone: string,
    forecast: string,
    time: string,
    perTimeUnit: string,
    workers: string,
    chartValues: number[],
    chartHours: string[],
}

const ChartTable = (props: Props) => {

    const { pwoDone, forecast, time, perTimeUnit, workers, chartValues, chartHours } = props;

    return (
        <div className='chart--table'>
            <div className='values--info'>
                <p className='value -big'>
                    {pwoDone}
                    <span>Wykonane PWO</span>
                </p>
                <p className='value -medium'>
                    {forecast}
                    <span>Prognoza nadań</span>
                </p>
                <div className='double--box'>
                    <p className='value -small'>
                        {perTimeUnit}
                        <span>{time}</span>
                    </p>
                    <p className='value -small'>
                        {workers}
                        <span>Pracownicy</span>
                    </p>
                </div>
            </div>
            <div className='charts--block'>
                <div className='chart --top'>
                    <BarChart values={chartValues} colorway={ChartColorway.primary} />
                </div>
                <p className='description --top'>Sorter automatyczny</p>
                <div className='chart --bottom'>
                    <BarChart values={chartValues} colorway={ChartColorway.secondary} />
                </div>
                <p className='description --bottom'>Sorter automatyczny</p>
                <div className='labels'>
                    {
                        chartHours.map((hour, index) => (
                            <span key={index}>{hour}</span>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default ChartTable