import React from 'react';
import './App.css';
import Home from './pages/Home/Home';

function App() {
  return (
    <Home />
  );  
}

export default App;