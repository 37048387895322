import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartColorway } from '../../../types/entities.d';

type Props = {
    colorway: ChartColorway;
    values: number[];
}

const BarChart = (props: Props) => {

    const { values, colorway } = props;

    return (
        <Bar
            plugins={[ChartDataLabels]}
            data={{
                labels: values.map((row, index) => index),
                datasets: [
                    {
                        backgroundColor: colorway === ChartColorway.primary ? '#FFCB04' : '#FFFFFF' ,
                        data: values.map(row => row),
                    }
                ],
            }}
            options={{
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        top: 140,
                        left: 50,
                        right: 50,
                    },
                },
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: false
                    },
                    datalabels: {
                        clamp: true,
                        align: 'top',
                        anchor: 'end',
                        offset: 7,
                        color: colorway === ChartColorway.primary ?  '#FFFFFF' : '#FFCB04',
                        font: {
                            size: 100,
                            weight: 700,
                            family: 'Montserrat',
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        }
                    },
                    y: {
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        }
                    }
                }
            }
        }
        />
    );
}

export default BarChart;