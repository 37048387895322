//style
import './LoadingPanel.scss';

const LoadingPanel = () => {
    return (
        <div className="loading--panel">
            <h2 className="heading">Wczytywanie...</h2>
        </div>
    );
}

export default LoadingPanel;