import { Departure, Shift } from "../../types/entities.d";
import './DeparturesTable.scss';
import Row from "./subcomponents/Row";

type Props = {
    shift: Shift;
    departures: Departure[],
}

const DepartmentsTable = (props: Props) => {

    const { shift, departures } = props;

    return (
        <div className="departments--table">
            <header className="header">
                <p className="text">Czas planowany</p>
                <p className="text">{shift === Shift.afternoon ? 'Pozostało minut' : 'Czas rzeczywisty'}</p>
                <p className="text">Trasa</p>
                <p className="text">Numer doku</p>
            </header>
            {
                departures.map((row, id) => (
                    <Row key={id} shift={shift} plannedTime={row.plannedTime} route={row.route} dockNumber={row.dockNumber} realTime={row.realTime} />
                ))
            }
        </div>
    );
}

export default DepartmentsTable;